import { Component, OnInit, Inject, Input, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { find as _find, pull as _pull } from 'lodash';
import QRCode from 'qrcode';
import { PatientStateService, NoctemUser, EmergencyContact, SimpleInstance, UserFuncService, ApplicationContext, AdditionalProviders } from '@noctem/web';
import { Router } from '@angular/router';
import { APPLICATION_ORGANIZATION } from '../../../../../../../../../noctem-lib/src/constants/constants';
import { UserStateService } from '../../../../../../../../../noctem-lib/src/lib/state';
import { FormGroup, NgModel, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';
import { TimeTrackingService } from '../../../../../../../../../noctem-lib/src/lib/services/time-tracking.service';

@Component({
  selector: 'manage-patient-dialog',
  styles: [
    `
      .mat-select {
        border: 1px solid rgb(215, 226, 229);
        padding: 6px 16px;
      }

      .self-assign-container button {
        margin-top: 16px;
      }

      .mat-dialog-content {
        max-height: 80vh;
      }

      input.ng-dirty.ng-touched.ng-invalid {
        border-bottom: 2px solid red;
        box-shadow: none;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .height {
        width: 2rem;
      }

      input[type=number] {
          -moz-appearance:textfield;
      }
    `
  ],
  templateUrl: './manage-patient-dialog.component.html' ,
  providers: [TimeTrackingService]

})
export class ManagePatientDialog implements OnInit {
  @Input() isExistingUser = false;
  
  newGroup: any = null;
  userGroups: SimpleInstance[] = [];
  assignedClinicians: any[] = [];
  prevName: string;
  mangeAction: string;
  races = [ 'White', 'Black or African American', 'Asian', 'American Indian and Alaska Native', 'Native Hawaiian and Other Pacific Islander', 'Other'];
  ethnicities = ['Hispanic', 'Non Hispanic', 'Prefer Not to Answer', 'Unknown'];
  maritalStatuses = ['Married', 'Widowed', 'Divorced', 'Separated', 'Never Married', 'Prefer Not to Answer', 'Unknown'];
  militaryStatuses = ['Active Duty', 'Reserve', 'IRR (Individual Ready Reserves)', 'Discharged/ Separated', 'Retired', 'Not Applicable', 'Unknown'];
  branches = ['NAVY', 'MARINE CORPS', 'ARMY', 'AIR FORCE', 'COAST GUARD'];
  ranks = ['E-1', 'E-2', 'E-3', 'E-4', 'E-5', 'E-6', 'E-7', 'E-8', 'E-9', 'O-1', 'O-2', 'O-3', 'O-4', 'O-5', 'O-6', 'O-7', 'O-8', 'O-9', 'O-10','W-1', 'W-2', 'W-3', 'W-4', 'W-5', 'Prefer not to Answer', "Don't know"];
  diagnosticCodes = [
    'F51.01 Primary insomnia',
    'F51.04 Psychophysiologic insomnia',
    'F51.05 Insomnia due to other mental disorder',
    'F51.3 Sleepwalking',
    'F51.5  Nightmare disorder',
    'G25.81 Restless legs syndrome',
    'G47.00 Insomnia (unspecified)',
    'G47.01 Insomnia due to medical condition',
    'G47.09 Other insomnia',
    'G47.10 Hypersomnia (unspecified)',
    'G47.20 Circadian rhythm sleep disorder (unspecified type)',
    'G47.21 Circadian rhythm sleep disorder (delayed sleep phase)',
    'G47.22 Circadian rhythm sleep disorder (advanced sleep phase)',
    'G47.23 Circadian rhythm sleep disorder (irregular sleep wake type)',
    'G47.24 Circadian rhythm sleep disorder (free running type)',
    'G47.25 Circadian rhythm sleep disorder (jet lag type)',
    'G47.26 Circadian rhythm sleep disorder (shift work type)',
    'G47.27 Circadian rhythm sleep disorder (in conditions classified elsewhere)',
    'G47.29 Other circadian rhythm sleep disorder',
    'G47.33 Obstructive sleep apnea (adult) (pediatric)',
    'G47.411 Narcolepsy (with cataplexy)',
    'G47.419 Narcolepsy (without cataplexy)',
    'G47.50 Parasomnia (unspecified)',
    'G47.52 REM sleep behavior disorder',
    'G47.61 Periodic limb movement disorder',
    'G47.63 Sleep related bruxism',
  ];
  disabledReasons = ['Completed Intervention', 'Dropped Out', 'Other'];
  cliniciansGroups = [];
  healthProviders: any[] = [{HpFirstName: '', HpLastName: '', HpEmail: '', HpOrg: ''}];
  
  public isActive: string;
  public disabledReason: string;

  public isValid = true;

  public medications: any = [];
  public medicationDisplay: any = [];
  public newMed: any;
  public newMedDose: any;
  public newMedDesc: any;
  public prescribedMedications = [];
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  patientForm: FormGroup = this.fb.group({
    firstName: ['', {updateOn: 'blur'}, Validators.required],
    lastName: ['' ,  {updateOn: 'blur'}, Validators.required ],
    gender: ['', {updateOn: 'blur'},  Validators.required ],
    age: ['', {validators: [Validators.required, Validators.min(17), Validators.max(120)], asyncValidators: [], updateOn: 'blur'}],
    emailAddress: ['', {validators: [Validators.pattern(this.emailPattern)], asyncValidators: [], updateOn: 'blur'}],
    phoneNumber: ['', {validators: [Validators.minLength(10), Validators.maxLength(12)], asyncValidators: [], updateOn: 'blur'}],
    feet: ['',  {validators: [Validators.required, Validators.min(3), Validators.max(7)], asyncValidators: [], updateOn: 'blur'} ],
    height: [''],
    inches: ['', {validators: [Validators.required, Validators.min(0), Validators.max(11)], asyncValidators: [], updateOn: 'blur'}],
    weight: ['', {validators: [Validators.required, Validators.min(50), Validators.max(500), Validators.maxLength(3)], asyncValidators: [], updateOn: 'blur'}],
    race: ['Unknown', Validators.required ],
    ethnicity: ['Unknown', Validators.required ],
    maritalStatus: ['Unknown', Validators.required ],
    militaryStatus: ['Unknown', Validators.required ],
    groups: [''],
    branch: [''],
    rank: [''],
    externalId: ['', Validators.pattern(/^[a-zA-Z0-9-_]+$/)],
    diagnosticCodes: [''],
    isActive: ['']
  });

  EC1Form: FormGroup = this.fb.group({
    firstName: [''],
    lastName: [''],
    email: [''],
    relation: ['']
  });

  EC2Form: FormGroup = this.fb.group({
    firstName: [''],
    lastName: [''],
    email: [''],
    relation: ['']
  });

  medForm: FormGroup = this.fb.group({
    newMed: [''],
    newMedDesc: [''],
    newMedDose: [''],
  });

  displayErrorMessage: boolean;
  isLoading = false;
  destroy$: Subject<any> = new Subject();

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<ManagePatientDialog>,
    public patientStateService: PatientStateService,
    private userService: UserFuncService,
    private userStateService: UserStateService,
    private appContext: ApplicationContext,
    private timeTrackingService: TimeTrackingService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isExistingUser = data.isExistingUser;
    this.mangeAction = this.isExistingUser ? 'Update' : 'Add';
  }

  async ngOnInit() {
    await this.patientStateService.getGroups();
    const user = this.appContext.User;
    const groups = user?.groups;
    groups?.forEach(o => {
      if (((o as any)._id  ?? (o as any).id) !== APPLICATION_ORGANIZATION._id) {
        this.cliniciansGroups.push({
          id: (o as any)._id ?? (o as any).id,
          display: o.display ?? (o as any).Name
        });
      }
    });

    this.validateMilitaryStatus();

    if (this.cliniciansGroups.length === 1) {
      this.userGroups = this.cliniciansGroups
    } else if (this.cliniciansGroups.length > 1 && !this.isExistingUser) {
      this.patientForm.get('groups').setValidators(Validators.required);
      this.patientForm.get('groups').updateValueAndValidity();    
    }
    if (this.isExistingUser) {
      const state = this.patientStateService.stateModel.get();
      // Transform from NoctemUser to local view model
      const userProfile = state.patientInfo.profile;
      if(state.prescribedMedicineList.length > 0){
        state.prescribedMedicineList.forEach(prescription => {
          this.prescribedMedications.push(prescription.medicine.display);
        });
      }
      // populate feet + inches fields from userProfile.height value
      const feet = userProfile.height ? Math.floor(userProfile.height / 12) : undefined;
      const inches = userProfile.height ? userProfile.height - feet * 12 : undefined;
      this.patientForm = this.fb.group({
        firstName: [userProfile.firstName, {updateOn: 'blur'}, Validators.required],
        lastName: [userProfile.lastName, {updateOn: 'blur'}, Validators.required],
        gender: [userProfile.gender,  {updateOn: 'blur'},  Validators.required],
        age: [userProfile.age, {validators: [Validators.required, Validators.min(17), Validators.max(120)], asyncValidators: [], updateOn: 'blur'}],
        emailAddress: [userProfile.emailAddress,{validators: [Validators.pattern(this.emailPattern)], asyncValidators: [], updateOn: 'blur'}],
        phoneNumber: [userProfile.phoneNumber, {validators: [Validators.minLength(10), Validators.maxLength(12)], asyncValidators: [], updateOn: 'blur'}],
        feet: [feet, {validators: [Validators.required, Validators.min(3), Validators.max(7)], asyncValidators: [], updateOn: 'blur'} ],
        inches: [inches, {validators: [Validators.required, Validators.min(0), Validators.max(11)], asyncValidators: [], updateOn: 'blur'}],
        height: [''],
        weight: [userProfile.weight,  {validators: [Validators.required, Validators.min(50), Validators.max(500), Validators.maxLength(3)], asyncValidators: [], updateOn: 'blur'}],
        race: [userProfile.race],
        ethnicity: [userProfile.ethnicity],
        maritalStatus: [userProfile.maritalStatus],
        militaryStatus: [userProfile.militaryStatus],
        groups: [''],
        branch: [userProfile.branch],
        rank: [userProfile.rank],
        externalId: [userProfile.externalId, Validators.pattern(/^[a-zA-Z0-9-_]+$/)],
        diagnosticCodes: [userProfile.diagnosticCodes],
        isActive: [userProfile.isActive],
        disabledReason: ['']
      });

      this.validateMilitaryStatus();

      this.prevName = userProfile.firstName + ' ' + userProfile.lastName;
      
      const firstContact = state.patientInfo.emergencyContacts[0];
      if (firstContact) {
        this.EC1Form = this.fb.group({
          firstName: [firstContact.firstName],
          lastName: [firstContact.lastName],
          email: [firstContact.email],
          relation: [firstContact.relation]
        });
      }

      const secondContact = state.patientInfo.emergencyContacts[1];
      if (secondContact) {
        this.EC2Form = this.fb.group({
          firstName: [secondContact.firstName],
          lastName: [secondContact.lastName],
          email: [secondContact.email],
          relation: [secondContact.relation]
        });
      }

      const additionalProviders = state.patientInfo.additionalProviders;
      if (additionalProviders) {
        this.healthProviders.pop();
        additionalProviders?.forEach(p => {
            this.healthProviders.push({
              HpFirstName: (p as any).firstName,
              HpLastName: (p as any).lastName,
              HpEmail: (p as any).email,
              HpOrg: (p as any).org
            })
        });
      }

      if (userProfile.phoneNumber == null || userProfile.phoneNumber == 'N/A') {
        this.patientForm.get('phoneNumber').setValue('Phone Unknown');
      }

      if (userProfile.race == null) {
        this.patientForm.get('race').setValue([this.races[7]]);
      }

      if (userProfile.ethnicity == null) {
        this.patientForm.get('ethnicity').setValue('Unknown');
      }
      if (userProfile.maritalStatus == null) {
        this.patientForm.get('maritalStatus').setValue('Unknown');
      }

      if (userProfile.militaryStatus == null) {
        this.patientForm.get('militaryStatus').setValue('Unknown');
      }

      this.userGroups = state.patientInfo.groups?.filter(
        g => g.id !== APPLICATION_ORGANIZATION._id
      );

      this.assignedClinicians = state.patientInfo.assignedClinician;
    }
  }
  
  ngAfterViewInit(){
    if(this.isExistingUser){
      this.timeTrackingService.trackTimeOnElement("mat-dialog-container", { activityType: 'Patient_Page', subEvent: 'Edit_Patient_Dialog', patientId: this.data.recipientId });
    }
  }

  async checkEmailForConflicts() {
    // Note: conflicts are determined via the UserSettings collection - not the user_security model per the API's method
    const targetEmailAddress = this.patientForm.get("emailAddress").value;

    // Given that emailAddress is now an optional field for patients, return true if DNE
    if(!targetEmailAddress) {
      return true;
    }

    if (this.isExistingUser) {
      // Check if changing email only
      const state = this.patientStateService.stateModel.get();
      const currentEmailAddress = state.patientInfo.profile.emailAddress
      if (targetEmailAddress !== currentEmailAddress) {
        const conflictingUsers = await this.patientStateService.getPatients({"Payload.profile.emailAddress": targetEmailAddress});
        return (conflictingUsers.length === 0) ? true : false;
      }

      return true;

    } else {
      // Always check for new Patients
      const conflictingUsers = await this.patientStateService.getPatients({"Payload.profile.emailAddress": targetEmailAddress});
      return (conflictingUsers.length === 0) ? true : false;
    }
  }

  async checkNameInGroups() {
    if (this.isExistingUser && this.prevName == this.patientForm.get('firstName').value + ' ' + this.patientForm.get('lastName').value) {
      return false;
    }
    const groups = this.userGroups;
    const orQuery = [];
    groups.map(g => {
      orQuery.push({ 'Payload.groups': { $elemMatch: { id: g.id } } });
    });
    const query = {
      // TODO: revisit regex for case insensitive search
      $and: [
        {
          'Payload.profile.lastName': { $eq: this.patientForm.get('lastName').value } // new RegExp(`^${this.lastName}$`, 'i')
        },
        {
          'Payload.profile.firstName': { $eq: this.patientForm.get('firstName').value }
        },
        {
          $or: orQuery
        }
      ]
    };
    const data = await this.patientStateService.getPatients(query);
    return data && data.length > 0;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  clearSelection(field) {
    const race = this.patientForm.get('race')
    if (race.value.includes('Prefer Not to Answer')) {
      race.setValue(['Prefer Not to Answer']);
    }
    else if (race.value.includes('Unknown')) {
      race.setValue(['Unknown']);
    }
  }

  private dirtyFields() {
    Object.keys(this.patientForm.controls).forEach(key => {
      this.patientForm.get(key).markAsDirty();
      this.patientForm.get(key).markAsTouched();
    });
  }

  onSubmit() {
    this.isLoading = true;
    if (!this.patientForm.valid || this.userGroups.length < 1) {
      this.dirtyFields();
      this.displayErrorMessage = true
      this.isLoading = false
      return false;    
    }

    this.checkEmailForConflicts().then(success => {
      if (!success) {
        throw new Error("Email address already exists in the database, please use a different one!");
      }
    }).then(() => this.checkNameInGroups())
    // this.checkNameInGroups()
    .then(found => {
      if (found) {
        alert(
          'The patient name (first name and last name combination) has been used within this group/clinic, please use a different name!'
        );
        this.isLoading = false
      } else {
        let confirmDisabledUser = true;
        if (this.patientForm.get('isActive') && this.patientForm.get('isActive').value === false) {
          confirmDisabledUser = confirm(`This patient has been marked as inactive, which will cause the patient to be removed from Roll Call, as well as remove patient's access from the Mobile Application.  Is this intended?`);
        }
        if (!this.isExistingUser) {
          this.createNewPatient();
        } else if (confirmDisabledUser) {
          // Populate NoctemUser object from view model
          const patientInfo = this.getFormData();
          this.patientStateService.savePatientInfo(patientInfo);
          this.patientStateService.onUserSaved$.pipe(takeUntil(this.destroy$)).subscribe(res => {
            this.userService.indexUser(res.userId).pipe(take(1)).subscribe(response => {
              this.dialogRef.close();
              this.patientStateService.setIsLoading(true)
              setTimeout(() => {this.patientStateService.setIsLoading(false)}, 200)
              if (this.patientForm.get('isActive').value === false) {
                this.router.navigateByUrl('/');
              }
            });
          });
        }
      }
    })
    .catch((err) => {
      this.isLoading = false;
      alert(err.message);
    });
  }

  private createNewPatient() {
    const personInstance = this.getFormData();
      this.patientStateService.addNewPatient(personInstance);
      this.patientStateService.onUserCreated$.pipe(takeUntil(this.destroy$)).subscribe(userId => {
        this.dialogRef.close();
        if (userId) {
          this.router.navigateByUrl(`/patient/${userId}`);
        }
      });
  }

  private getFormData() {
    const emergencyContacts: EmergencyContact[] = [this.EC1Form.value, this.EC2Form.value];
    let additionalProviders: AdditionalProviders[] = [];

    this.healthProviders?.forEach(p => {
      if((p.HpFirstName || p.HpLastName || p.HpEmail || p.HpOrg) !== ''){
        additionalProviders.push({
          firstName: (p as any).HpFirstName,
          lastName: (p as any).HpLastName,
          email: (p as any).HpEmail,
          org: (p as any).HpOrg
        })
      }
    });
    
    // combine feet + inches and save to height field
    this.patientForm.get('height').setValue(this.calculateHeight());

    const personInstance: NoctemUser = {
      ...new NoctemUser(),
      profile: this.patientForm.value,
      groups: this.userGroups,
      assignedClinician: this.assignedClinicians,
      emergencyContacts,
      additionalProviders
    };
    return personInstance;
  }

  public addGroup(allGroups, groupId: string) {
    const group = _find(allGroups, grp => grp.id === groupId);
    if (group) {
      const alreadyExists = this.userGroups.find(g => g.id === group.id);
      if (!alreadyExists) {
        const groupInstance = {
          id: group.id,
          display: group.display,
          model: 'Group'
        };
        this.userGroups.push(groupInstance);
      }
    }
  }

  public deleteGroup(groupId) {
    const doDelete = confirm('Are you sure you want to remove this group?');
    if (doDelete) {
      const thisGrp = _find(this.userGroups, group => group.id === groupId);
      this.userGroups = _pull(this.userGroups, thisGrp);
    }
  }

  public subscribeToPatient() {
    const { UserId, profile } = this.appContext.User; 
    if (!this.assignedClinicians) {
      this.assignedClinicians = [];
    }
    this.assignedClinicians.push({
      id: UserId,
      display: `${profile.firstName} ${profile.lastName}`,
      email: profile.emailAddress,
      phone: profile.phoneNumber,
      model: 'UserSettings',
    })
  }

  public unsubscribeFromPatient() {
    const doDelete = confirm('Are you sure you want to unsubscribe from this patient?');
    if (doDelete) {
      const clinicianToDelete = _find(this.assignedClinicians, c => c.id === this.appContext.User.UserId);
      this.assignedClinicians = _pull(this.assignedClinicians, clinicianToDelete);
    }
  }
  
  public isSubscribed() {
    return this.assignedClinicians?.find(c => c.id === this.appContext.User.UserId)
  }
  
  public addNewProvider(): void {
    const newObj = {HpFirstName:'', HpLastName: '', HpEmail: '', HpOrg: ''}
    this.healthProviders.push(newObj);
  }

  public removeProvider(index): void {
    this.healthProviders.splice(index, 1);
  }

  public addPrescribedMed(med){
    const state = this.patientStateService.stateModel.get();
    state.medicineList.forEach(medication => {
      if(med === medication.id){
        this.prescribedMedications.push(medication.name);
        return; }
      }) 
  }

  validateMilitaryStatus() {
    this.patientForm.get('militaryStatus').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      const rank = this.patientForm.get('rank');
      const branch = this.patientForm.get('branch');
      if (value === 'Unknown' || value === 'Not Applicable') {
        rank.clearValidators();
        branch.clearValidators();
        rank.updateValueAndValidity()
        branch.updateValueAndValidity();
        if (rank.value) { rank.setValue('')}
        if (branch.value) { branch.setValue('')}
      } else {
        rank.setValidators(Validators.required);
        branch.setValidators(Validators.required);
        rank.updateValueAndValidity()
        branch.updateValueAndValidity();
      }
    });
  }

  public removePrescribedMed(med){
    let index = this.prescribedMedications.findIndex(medicine => medicine === med.medicine.display)
    this.prescribedMedications.splice(index, 1)
  }
  private calculateHeight() {
   return (this.patientForm.get('feet').value * 12) + this.patientForm.get('inches').value;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
